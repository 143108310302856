import React, { useState, useCallback, useEffect } from 'react'
import { Form } from 'antd'
import { Table } from 'ant-table-extensions'
import { useParams } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import { UserTableStyleWrapper } from '../../pages/style'
import { TableWrapper } from '../../styled'

import { Button } from '../../../components/buttons/buttons'
import { Cards } from '../../../components/cards/frame/cards-frame'

import { useMutation } from '@apollo/client'
import { ARCHIVE_CAR } from '../../../graphql/AdminMutations'

import { useDispatch, useSelector } from 'react-redux'
import { deleteCar } from '../../../redux/cars/actionCreator'
import { useTranslation } from 'react-i18next'

import { CarDetailModalForm } from './tabs/carDetailModalForm'

const graphqlVariables = {
  tokenMutation: process.env.REACT_APP_TOKEN_MUTATION
}

const CarListTable = props => {
  let { id: carId } = useParams()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const tableData = useSelector(state => state.cars?.cars)

  const [form] = Form.useForm()

  const [state, setState] = useState({
    editVisible: false,
    update: {}
  })

  const [formReadonly, setFormReadonly] = useState(true)
  const [selectedCar, setSelectedCar] = useState({})

  const carsTableData = []

  // archive car
  const [triggerDeleteCar] = useMutation(ARCHIVE_CAR, {
    variables: graphqlVariables
  })

  tableData &&
    tableData.map(car => {
      const {
        id,
        driver_firstname,
        driver_lastname,
        licence_plate,
        branchOffice,
        carRenter,
        carOwner,
        storage
      } = car
      const company = branchOffice?.company || null
      const link = '/admin/car/edit/' + id
      let storageNumber = ''
      let speedometer = 0
      if (storage && storage.length > 0) {
        storageNumber = storage[storage.length - 1].number
        speedometer = storage[storage.length - 1].speedometer
      }
      return carsTableData.push({
        key: id,
        licence_plate: (
          <a key={id} href={link}>
            <span className={`status-text`}>{car?.licence_plate}</span>
          </a>
        ),
        link: process.env.REACT_APP_URL + '/admin/car/edit/' + id,
        firstname: driver_firstname,
        lastname: driver_lastname,
        company: company,
        owner: carOwner?.company || null,
        renter: carRenter?.company || null,
        storageNumber: storageNumber || null,
        speedometer: speedometer || null,
        action: (
          <div className='table-actions'>
            <>
              <Button
                onClick={() => showEditModal(car)}
                className='btn-icon'
                type='info'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='eye' size={16} />
              </Button>
              <Button
                onClick={() => showEditModal(car, 'edit')}
                className='btn-icon'
                type='info'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='edit' size={16} />
              </Button>
              <Button
                onClick={() => onHandleDelete(id)}
                className='btn-icon'
                type='danger'
                to='#'
                shape='circle'
              >
                <FeatherIcon icon='trash-2' size={16} />
              </Button>
            </>
          </div>
        )
      })
    })

  const showEditModal = (data, type = 'view') => {
    setEditFormFieldsValues(data)
    if (type === 'edit') {
      setFormReadonly(false)
    } else {
      setFormReadonly(true)
    }
    setState({
      ...state,
      editVisible: true,
      update: data
    })
    setSelectedCar(data)
  }

  const onHandleDelete = id => {
    const confirm = window.confirm(t('car.deleteCarQuestion'))
    if (confirm) {
      const variables = {
        ...graphqlVariables,
        carId: parseInt(id),
        active: false
      }

      triggerDeleteCar({ variables }).then(({ data }) => {
        const response = data?.archiveCar
        if (response.sucessful) {
          dispatch(deleteCar(id))
        }
      })
    }
  }

  const setEditFormFieldsValues = carInfo => {
    updateFormFieldValue('licence_plate', carInfo?.licence_plate)
    updateFormFieldValue('firstname', carInfo?.driver_firstname)
    updateFormFieldValue('lastname', carInfo?.driver_lastname)
    updateFormFieldValue('phone', carInfo?.driver_phone)
    updateFormFieldValue('email', carInfo?.driver_email)
    updateFormFieldValue('sendSms', carInfo?.sendSms)
    updateFormFieldValue('note', carInfo?.note)

    updateFormFieldValue('company', carInfo?.branchOffice?.company)
    updateFormFieldValue('owner', carInfo?.carOwner?.company)
    updateFormFieldValue('renter', carInfo?.carRenter?.company)
  }

  const updateFormFieldValue = useCallback(
    (fieldKey, fieldValue) => {
      form.setFieldsValue({ [fieldKey]: fieldValue })
    },
    [form]
  )

  const fields = {
    licence_plate: 'EČV',
    firstname: 'Meno',
    lastname: 'Priezvisko',
    company: 'Pneuservis',
    owner: 'Majiteľ',
    renter: 'Nájomca',
    storageNumber: 'Č. uskladnenia',
    speedometer: 'Km',
    link: {
      header: 'Link',
      formatter: (_fieldValue, record) => {
        return record?.link
      }
    }
  }

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className='table-responsive'>
          <Table
            dataSource={carsTableData}
            columns={getCarsTableColumns(t)}
            pagination={{
              defaultPageSize: 20,
              total: carsTableData.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${t('common.from')} ${total} ${t(
                  'common.items'
                )}`
            }}
            exportableProps={{
              fileName: 'zoznam-vozidiel',
              // showColumnPicker: true,
              fields,
              btnProps: {
                type: 'primary',
                children: <span>Export do CSV</span>
              }
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
      <CarDetailModalForm
        t={t}
        tableData={tableData}
        data={props}
        formReadonly={formReadonly}
        form={form}
        graphqlVariables={graphqlVariables}
        selectedCar={selectedCar}
        setState={setState}
        showModal={state.editVisible}
      />
    </Cards>
  )
}

const getCarsTableColumns = t => {
  return [
    {
      title: t('car.licencePlate'),
      dataIndex: 'licence_plate',
      key: 'licence_plate'
    },
    {
      title: t('car.company'),
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: t('car.owner'),
      dataIndex: 'owner',
      key: 'owner'
    },
    {
      title: t('car.renter'),
      dataIndex: 'renter',
      key: 'renter'
    },
    {
      title: t('car.storageNumber'),
      dataIndex: 'storageNumber',
      key: 'storageNumber'
    },
    {
      title: t('car.speedometer'),
      dataIndex: 'speedometer',
      key: 'speedometer'
    },
    {
      title: t('car.driverFirstname'),
      dataIndex: 'firstname',
      key: 'firstname'
    },
    {
      title: t('car.driverLastname'),
      dataIndex: 'lastname',
      key: 'lastname'
    },
    {
      title: t('common.actions'),
      dataIndex: 'action',
      key: 'action',
      width: '90px'
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      hidden: true
    }
  ].filter(item => !item.hidden)
}

export default CarListTable
